import { FC, MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { IoLogoLinkedin } from 'react-icons/io5';
import useRouter from 'next/router';
import FacebookLogin from 'react-facebook-login';
import get from 'lodash/get';
import useTranslation from 'next-translate/useTranslation';
import { facebookLogin, linkedInLogin, loginSuccess } from 'redux-saga/actions';
import LinkedinIcon from 'public/assets/svg/linkedin_rounded.svg';
import MLoading from './MLoading';

interface Props {
  isRegister?: boolean;
  isSubscribe?: boolean;
  referralCode?: string;
  isAgree?: boolean;
  onShowError?: () => void;
}

export interface FacebookParams {
  access_token: string;
  type: number;
  subscribe: boolean;
  source: number;
  language: string;
  referral_code?: string;
}

export interface LinkedinParams {
  code: string;
  type: number;
  subscribe: boolean;
  source: number;
  language: string;
  callback_url: string;
  referral_code?: string;
}

const useStyles = makeStyles(() => ({
  prevent: {
    cursor: 'pointer',
    position: 'relative',
    '&:before': {
      content: ({ isRegister, isAgree }: Props) => (!isAgree && isRegister ? '""' : ''),
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: 1,
      top: 0,
      left: 0,
    },
  },
}));

const MSocialLoginButton: FC<Props> = ({ isRegister, isSubscribe, isAgree, onShowError, referralCode }) => {
  const { t: commonT } = useTranslation('common');
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles({ isAgree, isRegister });
  let currentUrl = '';

  if (typeof window !== 'undefined') {
    currentUrl = window.location.origin;
  }

  const linkedInApi = {
    clientId: process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_ID,
    redirectUrl: `${currentUrl}/linkedin`,
    oauthUrl: 'https://www.linkedin.com/oauth/v2/authorization?response_type=code',
    scope: 'r_liteprofile%20r_emailaddress',
  };

  const facebookApi = {
    appId: process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID,
    fields: 'name,email,picture',
    callback: (response) => responseFacebook(response),
  };

  const handlePostMessage = (event) => {
    if (event.data.type === 'code') {
      const { code } = event.data;
      responseLinkedIn(code, isSubscribe);
    }
  };

  const handleNextPage = (isOnBoarding: boolean) => {
    const nextPage = !isOnBoarding ? '/onboarding' : '/my-cvs';
    useRouter.push(nextPage);
  };

  const callbacks = {
    onFailure: (_error) => {
      const error = get(_error, ['non_field_errors', 0]) || get(_error, ['referral_code', 0]);
      if (error) enqueueSnackbar(error, { variant: 'error' });
    },
    onFinish: () => setIsLoading(false),
    onSuccess: (data) => {
      const error = get(data, ['non_field_errors', 0]);
      if (error) {
        enqueueSnackbar(error, { variant: 'error' });
      } else {
        dispatch(loginSuccess(data));
        handleNextPage(data.user.onboarding_urbancv);
      }
    },
  };

  const responseFacebook = (response) => {
    setIsLoading(response.status !== 'unknown'); // prevent for infinite loading
    if (response.accessToken) {
      const params: FacebookParams = {
        access_token: response.accessToken,
        type: 1,
        subscribe: isRegister ? isSubscribe : false,
        source: 4,
        language: 'en',
      };
      if (referralCode) params.referral_code = referralCode;
      dispatch(facebookLogin(params, callbacks));
    }
  };

  const responseLinkedIn = (code, subscribe) => {
    setIsLoading(true);
    if (code) {
      const params: LinkedinParams = {
        code,
        type: 1,
        subscribe: isRegister ? subscribe : false,
        source: 4,
        language: 'en',
        callback_url: linkedInApi.redirectUrl,
      };
      if (referralCode) params.referral_code = referralCode;

      dispatch(linkedInLogin(params, callbacks));
    }
  };

  const validateRegister = (_: MouseEvent<HTMLElement>) => {
    if (!isAgree && isRegister) {
      onShowError();
    }
  };

  const loginByLinked = () => {
    const { clientId, redirectUrl, oauthUrl, scope } = linkedInApi;
    const url = `${oauthUrl}&client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUrl}`;
    const width = 450;
    const height = 730;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    window.open(
      url,
      'Linkedin',
      `menubar=no,location=no,scrollbars=no,status=no,width=${width},height=${height},top=${top},left=${left}`,
    );
  };

  useEffect(() => {
    window.addEventListener('message', handlePostMessage);
    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, [isSubscribe]);

  const renderButton = () => {
    if (!isRegister) {
      return (
        <>
          <FacebookLogin
            appId={facebookApi.appId}
            fields={facebookApi.fields}
            callback={facebookApi.callback}
            isMobile={false}
            icon="fa-facebook"
            cssClass="m-button-social__facebook m-button-social__facebook--large"
            textButton={commonT('loginBySocial', { social: 'Facebook' })}
          />
          <Button
            className="m-button-social__linkedin m-button-social__linkedin--large"
            onClick={loginByLinked}
            startIcon={<IoLogoLinkedin size={16} />}
          >
            {commonT('loginBySocial', { social: 'Linkedin' })}
          </Button>
        </>
      );
    }
    return (
      <>
        <Box onClick={validateRegister} className={classes.prevent}>
          <FacebookLogin
            appId={facebookApi.appId}
            fields={facebookApi.fields}
            callback={facebookApi.callback}
            isMobile={false}
            icon="fa-facebook"
            cssClass="m-button-social__facebook m-button-social__facebook--small"
            textButton=""
          />
        </Box>
        <Box onClick={validateRegister} className={classes.prevent}>
          <Button className="m-button-social__linkedin m-button-social__linkedin--small" onClick={loginByLinked}>
            <LinkedinIcon />
          </Button>
        </Box>
      </>
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="m-button-social"
      gridGap="20px 6px"
      padding="24px 0px"
    >
      {isLoading && <MLoading fullPage />}
      {renderButton()}
    </Box>
  );
};

export default MSocialLoginButton;
