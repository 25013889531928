import React, { FC } from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import MTextField, { InputProps } from 'components/molecules/MTextField';

interface EyesIconProps {
  isShowPassword: boolean;
}

const EyesIcon: FC<EyesIconProps & IconButtonProps> = ({ isShowPassword = false, onClick }) => {
  return (
    <InputAdornment position="end">
      <IconButton aria-label="toggle password visibility" onClick={onClick} edge="end">
        {isShowPassword ? <MdVisibility /> : <MdVisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
};

const MPasswordField: FC<InputProps & TextFieldProps> = ({ title, id, helperText, ...other }) => {
  const [values, setValues] = React.useState<{ showPassword: boolean }>({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  return (
    <div>
      <MTextField
        id={id}
        title={title}
        helperText={helperText}
        type={values.showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: <EyesIcon isShowPassword={values.showPassword} onClick={handleClickShowPassword} />,
        }}
        {...other}
      />
    </div>
  );
};

export default MPasswordField;
