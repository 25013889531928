import { FC } from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
  fullPage?: boolean;
  fullComponent?: boolean;
}

const MLoading: FC<Props> = ({ fullPage = false, fullComponent = false }) => {
  return fullComponent || fullPage ? (
    <Box position={fullPage ? 'fixed' : 'absolute'} className="c-loading-overlay u-flex-center">
      <CircularProgress />
    </Box>
  ) : (
    <CircularProgress />
  );
};

export default MLoading;
